.c-strategy {
  border: 2px solid var(--color-neutral-50);
  display: flex;
  flex-direction: column;
  margin: var(--space-stack-default);
}

.c-strategy__link {
  text-decoration: none;
  color: var(--color-black);
}

.c-strategy__title {
  background-color: var(--color-neutral-50);
  font-size: var(--font-size-40);
  margin: 0;
  padding: var(--space-20);
  line-height: var(--line-height-20);
  cursor: pointer;
}

.c-strategy__body {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  justify-content: space-between;
}

.c-strategy__content {
  margin: var(--space-stack-default);
  padding: var(--space-20);
}

.c-strategy__meta {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  font-weight: bold;
}

.c-strategy__weight {
  padding: var(--space-20);
}

.c-strategy__favorite {
  padding: var(--space-20);
}

.c-strategy__favorite--is-favorite {
  background-color: var(--color-primary-50);
}

.c-strategy__favorite--is-favorite .c-icon__path {
  fill: var(--color-white);
}

.c-content {
  grid-column-end: 3;
  grid-column-start: 1;
  width: 100%;
}

@media screen  and (min-width: 600px) {
  .c-content {
    grid-column-end: 2;
    grid-column-start: 3;
  }
}

.c-content__title {
  background-color: var(--color-primary-50);
  color: var(--color-white);
  padding: var(--space-squish-20);
  font-size: var(--font-size-30);
  font-weight: bold;
}

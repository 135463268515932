.c-icon {
  fill: var(--color-white);
}

.c-icon--help {
  width: 18px;
}

.c-icon--close {
  width: 18px;
}

.c-icon--close--small {
  width: 14px;
}

.c-icon--favorite {
  width: 40px;
}

.c-icon--favorite .c-icon__path {
  fill: var(--color-black);
}

.c-icon-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

/*------------------------------------*\
  #BASE-TYPE
\*------------------------------------*/

*,
*:after,
*:before {
  box-sizing: border-box;
}

html {
  color: var(--color-neutral-90);
  font-family: var(--font-family-body);
  font-weight: var(--font-weight-default);
  font-size: var(--font-size-base);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  -webkit-text-size-adjust: 100%;
  height: 100%;
}

body {
  height: 100%;
  margin: 0;
  line-height: var(--line-height-default);
}

/*
 * Headings
 */
h1, h2, legend, h3, h4, h5, h6 {
  font-family: var(--font-family-heading);
  font-weight: var(--font-weight-30);
  font-size: var(--font-size-50);
  margin: var(--space-stack-default);
}

/*
 * Paragraphs
 */
p {
  margin: var(--space-stack-default);
  line-height: var(--line-height-30);
}


/*
 * Links
 */
a {
  color: var(--color-primary-50);
  cursor: pointer;

  &:visited {
    color: var(--color-neutral-50);
  }

  &:hover {
    color: var(--color-primary-70);
  }
}


/*
 * Other
 */
small {
  color: var(--color-neutral-50);
  font-size: var(--font-size-20);
}

em {
  font-style: italic;
}

strong {
  font-weight: var(--font-weight-30);
}

code, pre {
  font-family: 'courier new', monospace;
}

pre {
  margin: 0;
  overflow-x: auto;
}

abbr {
  border-bottom: 1px dotted var(--color-neutral-50);
}

del {
  text-decoration: line-through;
}

mark {
  background: color(yellow alpha(-50%));
}

figure {
  margin: 0;
}

hr {
  background-color: transparent;
  border: solid var(--color-neutral-30);
  border-width: 1px 0 0;
  clear: both;
  margin: 2.5rem var(--space-default);
}

ul,
ol {
  margin: 0;
  padding: 0;
}

.c-favorite {
  border-bottom: 2px solid var(--color-neutral-50);
  display: grid;
  grid-template-columns: auto 30px;
  margin: 0;
  line-height: var(--line-height-20);
}

.c-favorite__title {
  padding: var(--space-10);
  text-decoration: none;
  color: var(--color-black);
}

.c-favorite__close {
  background-color: var(--color-secondary-50);
  height: 100%;
}

.c-favorite__close .c-icon--favorite .c-icon__path {
  fill: var(--color-white);
}

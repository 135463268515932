.c-info {
	background-color: rgba(0, 0, 0, .4);
	display: none;
	height: 100%;
	left: 0;
	overflow: auto;
	position: fixed;
	top: 0;
	width: 100%;
	z-index: var(--level-10);
}

.c-info__inner {
	animation-duration: 0.4s;
	animation-name: animatetop;
	background-color: var(--color-white);
	border: 1px solid #888;
	box-shadow: 0 4px 8px 0 rgba(0, 0, 0, .2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  position: relative;
  margin: var(--space-30) auto;
}

@media screen  and (min-width: 600px) {
  .c-info__inner {
    margin: var(--space-60) auto;
    padding: var(--space-40);
    width: 80%;
  }
}

.c-info__header {
  align-items: baseline;
  background-color: var(--color-secondary-50);
  display: flex;
  justify-content: space-between;
  margin: var(--space-stack-default);
}

.c-info__header--strategy {
  background-color: var(--color-primary-50);
}

.c-info__header--aspect {
  background-color: var(--color-tertiary-50);
}

.c-info__heading {
  color: var(--color-white);
  font-size: var(--font-size-60);
  line-height: var(--line-height-default);
  margin: 0;
  padding-left: var(--space-default);
}

.c-info__close {
  padding: var(--space-default);
}

.c-info__content {
  padding: var(--space-default);
}

.c-info__content__title {
  margin-top: var(--space-40);
}

.c-info--is-visible {
  display: block;
}

@keyframes animatetop {
  from {top: -300px; opacity: 0}
  to {top: 0; opacity: 1}
}

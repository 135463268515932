.c-menu {
  grid-column-end: 3;
  grid-column-start: 1;
}

@media screen  and (min-width: 600px) {
  .c-menu {
    grid-column-end: 2;
    grid-column-start: 1;
  }
}

.c-menu__title {
  background-color: var(--color-neutral-50);
  font-size: var(--font-size-30);
  font-weight: bold;
  margin: 0;
  padding: var(--space-squish-20);
}

.c-menu__block {
  margin: var(--space-stack-default);
}

.c-content-meta {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: var(--space-stack-20);
}

.c-content-meta__amount,
.c-content-meta__sort {
  font-size: var(--font-size-20);
}

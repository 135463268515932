:root {

  /*
   * Typography
   */

  /* Fonts */
  --font-family-body: "Myriad Pro", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  --font-family-heading: var(--font-family-body);

  /* Font size */
  --font-size-base: 16px;

  --font-size-default: 1rem;
  --font-size-10: 0.75rem;
  --font-size-20: 0.875rem;
  --font-size-30: 1rem;
  --font-size-40: 1.125rem;
  --font-size-50: 1.35rem;
  --font-size-60: 1.75rem;
  --font-size-70: 2.5rem;
  --font-size-default: var(--font-size-30);

  /* Line-height */
  --line-height-default: 1em;
  --line-height-10: 1em;
  --line-height-20: 1.25em;
  --line-height-30: 1.5em;
  --line-height-default: var(--line-height-10);

  /* Letter spacing */
  --letter-spacing-default: 2px;

  /* Font weight */
  --font-weight-default: 400;
  --font-weight-10: 300;
  --font-weight-20: 400;
  --font-weight-30: 500;
  --font-weight-default: var(--font-weight-20);

  /*
   * Spacing
   */

  /* Spacing system */
  --space-default: 16px;
  --space-10: 4px;
  --space-20: 8px;
  --space-25: 12px; /* Not default */
  --space-30: 16px;
  --space-40: 32px;
  --space-50: 64px;
  --space-60: 80px;

  --space-stack-default: 0 0 16px 0;
  --space-stack-10: 0 0 4px 0;
  --space-stack-20: 0 0 8px 0;
  --space-stack-30: 0 0 16px 0;
  --space-stack-40: 0 0 32px 0;
  --space-stack-50: 0 0 64px 0;

  --space-inset-default: 16px 16px 0.1px 16px;
  --space-inset-10: 4px 4px 0.1px 4px;
  --space-inset-20: 8px 8px 0.1px 8px;
  --space-inset-30: 16px 16px 0.1px 16px;
  --space-inset-40: 32px 32px 16px 32px;
  --space-inset-50: 64px 64px 48px 64px;

  --space-squish-10: 4px 8px;
  --space-squish-20: 8px 16px;
  --space-squish-25: 12px 24px; /* Not default */
  --space-squish-30: 16px 32px;

  /* Grid gutter */
  --gutter: 24px;

  /* Maximum width of the website */
  --max-width: 1140px;

  /* Breakpoints */
  @custom-media --breakpoint-10 (min-width: 600px);
  @custom-media --breakpoint-20 (min-width: 750px);
  @custom-media --breakpoint-30 (min-width: 1000px);
  @custom-media --breakpoint-40 (min-width: 1200px);

  /*
   * Colors
   */

  /* Base colors */
  --color-primary-50: #99C17B;
  --color-secondary-50: #D98A5A;
  --color-tertiary-50: #7C8CB1;

  /* Success and positive colors */

  /* Error and negative colors */

  /* Neutral or gray colors */
  --color-neutral-50: #DDDCDA;

  /* Black and white */
  --color-black: #4F4F4F;
  --color-white: #fff;

  /*
   * Other
   */

  /* Rounded borders */
  --border-radius-default: 2px;
  --border-radius-10: 2px;
  --border-radius-20: 4px;
  --border-radius-50: 32px;

  /* Shadows */
  --box-shadow-default: 0px 2px 4px 1px color(var(--color-black) alpha(10%));
  --box-shadow-10: 0px 2px 4px 1px color(var(--color-black) alpha(10%));
  --box-shadow-30: 0px 8px 16px color(var(--color-black) alpha(20%));

  /* Animation beziers */
  --bezier-a: cubic-bezier(0.075, 0.82, 0.165, 1);

  /* Z-index plan */
  --layer-10: 10;
  --layer-20: 20;
  --layer-30: 30;
}

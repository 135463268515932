.c-header {
  border-bottom: 2px solid var(--color-neutral-50);
  margin: var(--space-stack-40);
}

.c-header__title {
  color: var(--color-secondary-50);
  font-weight: bold;
  font-size: var(--font-size-60);
  padding-top: var(--space-40);
  margin: var(--space-stack-20);
  text-align: center;

}

.c-header__subtitle {
  text-align: center;
}

.c-strategies {
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: var(--gutter);
  margin: var(--space-stack-default);

}

@media screen  and (min-width: 600px) {
  .c-strategies {
    grid-template-columns: 1fr 1fr;
  }
}

@media screen  and (min-width: 1000px) {
  .c-strategies {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

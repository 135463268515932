.c-category {
  border-bottom: 2px solid var(--color-neutral-50);
  display: grid;
  margin: 0;
  grid-template-columns: 30px auto 30px;
}

.c-category--aspect {
  padding-left: var(--space-default);
}

.c-category--aspect .c-category__help {
  background-color: var(--color-tertiary-50);
}

.c-category__checkbox {
  margin-right: var(--space-20);
  padding: var(--space-10);
  align-self: center;
}

.c-category__checkbox:checked + .c-category__label {
  font-weight: bold;
}

.c-category__label {
  padding: var(--space-10);
}

.c-category__help {
  background-color: var(--color-secondary-50);
  color: var(--color-white);
  text-align: center;
  padding: var(--space-10);
}
